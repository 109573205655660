import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@reapit/elements';

import { Document, Page, pdfjs } from 'react-pdf';

import {
	CloseButton,
	DownloadButton,
	LeftArrow,
	Modal,
	RightArrow,
	Spotlight,
	Caption,
} from './__styles__';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HEIGHT = 800;

const PDFViewer = ({ pdfData, isOpen, onClose, onDownload, noCaptions }) => {
	const [numPages, setNumPages] = useState(null);
	const [currPage, setCurrPage] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const handleClickPrev = () => {
		if (currPage > 1) setCurrPage(currPage - 1);
	};

	const handleClickNext = () => {
		if (currPage < numPages) setCurrPage(currPage + 1);
	};

	const keyDownCapture = useCallback((event) => {
		switch (event.key) {
			case 'Escape':
				onClose();
				break;

			case 'ArrowLeft':
				handleClickPrev();
				break;

			case 'ArrowRight':
				handleClickNext();
				break;
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', keyDownCapture, false);

		return () => {
			document.removeEventListener('keydown', keyDownCapture, false);
		};
	}, []);

	return (
		<Modal style={{ display: isOpen ? 'block' : 'none' }}>
			<CloseButton onClick={onClose}>
				<Icon icon="closeSystem" iconSize="small" intent="neutral" />
			</CloseButton>
			{onDownload && (
				<DownloadButton onClick={onDownload}>
					<Icon icon="downloadSystem" iconSize="small" intent="neutral" />
				</DownloadButton>
			)}
			{currPage !== 1 && (
				<LeftArrow onClick={handleClickPrev}>
					<Icon icon="arrowLeftSystem" iconSize="large" intent="neutral" />
				</LeftArrow>
			)}
			{currPage !== numPages && (
				<RightArrow onClick={handleClickNext}>
					<Icon icon="arrowRightSystem" iconSize="large" intent="neutral" />
				</RightArrow>
			)}
			<Spotlight style={{ height: HEIGHT }}>
				<Document
					file={pdfData}
					loading={null}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page pageNumber={currPage} renderAnnotationLayer={false} />
				</Document>
			</Spotlight>
			{!noCaptions && (
				<Caption>
					Page {currPage || (numPages ? 1 : '--')} of {numPages || '--'}
				</Caption>
			)}
		</Modal>
	);
};

PDFViewer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	pdfData: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired,
	onDownload: PropTypes.func,
	noCaptions: PropTypes.bool,
};

export default PDFViewer;
